import React, { useState } from "react"

const Toggleable = props => {
  const [show, setShow] = useState(true)
  const toggleShow = () => setShow(!show)
  return (
    <>
      <h2 style={{ display: "flex", justifyContent: "space-between" }}>
        {props.header}
        <button
          onClick={toggleShow}
          style={{ background: "none", border: "none", color: "rebeccapurple" }}
        >
          {show ? <>&#9650;</> : <>&#9660;</>}
        </button>{" "}
      </h2>
      {show && props.children}
    </>
  )
}

export default Toggleable
