import React, { Fragment, useContext, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import twinSize from "../../images/sizes/twin.png"
// import twinXlSize from "../images/sizes/twin-xl.png"
import fullSize from "../../images/sizes/full.png"
import queenSize from "../../images/sizes/queen.png"
import kingSize from "../../images/sizes/king.png"
import calKingSize from "../../images/sizes/cal-king.png"
import StoreContext from "../../context/StoreContext"

import Bed from "./Bed"
import Toggleable from "./Toggleable"
import Footer from "./Footer"

const sizeConfig = {
  twin: { name: "twin", image: twinSize },
  full: { name: "full", image: fullSize },
  queen: { name: "queen", image: queenSize },
  king: { name: "king", image: kingSize },
  calKing: { name: "cal king", image: calKingSize },
}

const sizes = Object.keys(sizeConfig)

const isEligible = title =>
  !["dev", "bundle", "test", "update"].find(term =>
    title.toLowerCase().includes(term)
  )

const categories = ["comforter", "duvet", "sheets", "pillow"]
const emptyBed = () => {
  const emptyBed = {}
  categories.forEach(category => (emptyBed[category] = null))
  return emptyBed
}

const products = data => data.allShopifyProduct.edges.map(({ node }) => node)

const eligibleProducts = data =>
  products(data).filter(product => isEligible(product.title))

const productsForCategory = (category, products) =>
  products.filter(({ title }) => title.toLowerCase().includes(category))

const categoriesWithProducts = data => {
  const products = eligibleProducts(data)
  return categories.map(category => ({
    category,
    products: productsForCategory(category, products),
  }))
}

const sizeForVariant = variant => {
  let size
  if (variant.selectedOptions) {
    size = variant.selectedOptions.find(({ name, value }) => name === "Size")
      .value
  }
  return size
}

const categoriesWithVariants = data =>
  categoriesWithProducts(data).map(({ category, products }) => ({
    category,
    variants: products.flatMap(product =>
      product.variants.map(variant => ({
        category,
        product,
        ...variant,
        size: sizeForVariant(variant),
      }))
    ),
  }))

const categoriesWithVariantsForSize = (data, targetSize) =>
  categoriesWithVariants(data).map(({ category, variants }) => ({
    category,
    variants: variants.filter(({ size }) => {
      // console.log("size", size)
      return size.toLowerCase().includes(targetSize)
    }),
  }))

const showFooter = bed => {
  const s = !!Object.values(bed).find(variant => !!variant)
  console.log("show Footer?", s)
  console.log(Object.values(bed).find(variant => !!variant))
  return s
}

const BedBuilder = ({ data }) => {
  const debug = false
  const [size, setSize] = useState(sizes[2])
  const [bed, setBed] = useState(emptyBed())
  const [makingBed, setIsMakingBed] = useState(false)

  const { addVariantToCart } = useContext(StoreContext)

  useEffect(() => {
    console.log("render")
  })

  useEffect(() => {
    console.log("render size")
    setBed({})
  }, [size])

  useEffect(() => {
    console.log("render bed")
  }, [bed])
  console.log("making bed", makingBed)

  const updateBed = (category, variant) => {
    console.log("update bed", category, variant.title)
    setIsMakingBed(true)
    setBed(prevBed => {
      const newBed = Object.assign(prevBed)
      newBed[category] = `${variant.product.title} ${variant.title}`
      newBed[category] = variant
      console.log("new bed", newBed)
      return newBed
    })
    setTimeout(() => {
      setIsMakingBed(false)
    }, 1)
  }

  const addAllToCart = variantIds => {
    console.log("variant ids", variantIds)
    addVariantToCart(variantIds, 1)
  }

  return (
    <div style={{ maxWidth: "320px", margin: "auto", marginTop: "75px" }}>
      <h1 style={{ fontSize: "xx-large" }}>bed builder</h1>
      {showFooter(bed)}
      <Toggleable header={`size: ${size}`}>
        <Carousel
          emulateTouch={true}
          showArrows={true}
          showStatus={false}
          infiniteLoop={true}
          showThumbs={false}
          showIndicators={false}
          centerMode={false}
          onChange={index => console.log(index)}
          selectedItem={sizes.indexOf(size)}
          onClickItem={index => setSize(sizes[index])}
        >
          {sizes.map(size => (
            <div key={size} style={{ border: "1px solid black" }}>
              <img src={sizeConfig[size].image} alt="bed size" />
            </div>
          ))}
        </Carousel>
      </Toggleable>

      {categoriesWithVariantsForSize(data, size).map(
        ({ category, variants }) => (
          <Fragment key={category}>
            <Toggleable
              header={
                (bed[category] &&
                  `${bed[category].product.title} ${bed[category].title}`) ||
                `choose ${category}`
              }
            >
              <Carousel
                emulateTouch={true}
                showArrows={true}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
                showIndicators={false}
                centerMode={false}
                onClickItem={index => updateBed(category, variants[index])}
              >
                {variants.map(variant => (
                  <div key={variant.id} style={{ border: "1px solid black" }}>
                    <img src={variant.image.originalSrc} alt="product detail" />
                    <p className="legend">
                      {variant.product.title} - {variant.title} - {variant.size}
                    </p>
                  </div>
                ))}
              </Carousel>
            </Toggleable>
            <br />
          </Fragment>
        )
      )}
      <Link to="/bedbuilder">BB</Link>
      {debug && (
        <>
          <h1 style={{ marginTop: "100px" }}>DEBUG</h1>
          {categoriesWithVariants(data).map(({ category, variants }) => (
            <>
              <h1>{category}</h1>
              <ul>
                {variants.map(variant => (
                  <li>
                    <h2>{variant.product.title}</h2>
                    <h3>{variant.title}</h3>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </>
      )}
      {showFooter(bed) && (
        <Footer>
          <Bed data={bed} onAddToCart={addAllToCart} />
        </Footer>
      )}
    </div>
  )
}

export default BedBuilder
export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          title
          shopifyId
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          id
          variants {
            id
            title
            shopifyId
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`
