import React from "react"

const truncate = (str, length) =>
  str.length > length ? `${str.substring(0, length)}...` : str

const Bed = ({ data, onAddToCart, debug = false }) => {
  if (!data) {
    return "-"
  }
  const variants = Object.entries(data).filter(
    ([category, variant]) => !!variant
  )
  const totalPrice = Object.values(data).reduce(
    (acc, variant) => acc + ((variant && parseFloat(variant.price)) || 0),
    0
  )
  const variantIds = Object.values(data)
    .map(variant => variant && variant.shopifyId)
    .filter(val => !!val)
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {totalPrice > 0 && (
        <table>
          <thead>
            <tr>
              {/*<th>Category</th>*/}
              <th>Product</th>
              {/*<th>Options</th>*/}
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {variants.map(([category, variant], index) => (
              <tr key={(variant && variant.id) || index}>
                {/*<td>{category}</td>*/}
                <td style={{ textAlign: "left" }}>
                  {variant &&
                    truncate(`${variant.product.title} ${variant.title}`, 30)}
                </td>
                {/*<td>{variant && variant.product.title}</td>*/}
                {/*<td>{variant && variant.title}</td>*/}
                <td style={{ textAlign: "right" }}>
                  ${variant && variant.price}
                </td>
              </tr>
            ))}
            <tr>
              <td style={{ textAlign: "right" }}>
                {variantIds.length > 0 && onAddToCart && (
                  <button
                    onClick={() => onAddToCart(variantIds)}
                    style={{ padding: "7px" }}
                  >
                    Add All to Cart
                  </button>
                )}
              </td>
              <td style={{ textAlign: "right" }}>${totalPrice.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      )}
      {debug && <pre>{JSON.stringify(data, null, " ")}</pre>}
    </div>
  )
}

export default Bed
